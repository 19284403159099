import {
  SHOW_VIEW_PAGE,
  HIDE_VIEW_PAGE,
  SET_KEY_NAME,
} from "../../actions/PageNavigations/types";

const initialState = {
  viewPage: false,
  key: "",
};

export default function handlePageNavigationReducer(
  PageNavigationState = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_VIEW_PAGE:
      return {
        ...PageNavigationState,
        viewPage: true,
      };
    case HIDE_VIEW_PAGE:
      return {
        ...PageNavigationState,
        viewPage: false,
      };
    case SET_KEY_NAME:
      return {
        ...PageNavigationState,
        key: payload,
      };

    default:
      return PageNavigationState;
  }
}
