import { SET_LOGIN_DETAILS, RESET_APP } from "./types";

export const handleSetLoginDetails = data => async dispatch => {
  try {
    dispatch({
      type: SET_LOGIN_DETAILS,
      payload: data
    });
  } catch (error) {}
};

export const handleResetApp = data => async dispatch => {
  try {
    dispatch({
      type: RESET_APP
    });
  } catch (error) {}
};
