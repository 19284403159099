import {
  VIEW_EARINIGCOMPLETE_PAGE,
  VIEW_EARINIGCOMPLETE_PAGE_Hide,
  VIEW_ChooseMounting_PAGE_Hide,
  VIEW_ChooseMounting_PAGE_View,
  VIEW_ViewEditChooseThisMounting,
  VIEW_HideEditChooseThisMounting
} from "../../actions/chooseYourEarning/types";

const initialState = {
  isViewEarningPageDetails: false,
  selectedRow: null,
  isViewChooseMountingPage: false,
  isViewEditChooseYourSetting: false,
  
};

export default function handleChooseYourEarningStudSetting(
  chooseYourEarning = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case VIEW_EARINIGCOMPLETE_PAGE:
      return {
        ...chooseYourEarning,
        isViewEarningPageDetails: true,
        selectedRow: payload
      };
    case VIEW_EARINIGCOMPLETE_PAGE_Hide:
      return {
        ...chooseYourEarning,
        isViewEarningPageDetails: false,
       // selectedRow: payload
      };
    case VIEW_ChooseMounting_PAGE_Hide:
      return {
        ...chooseYourEarning,
        isViewChooseMountingPage: false,
        // selectedRow: payload
      };
    case VIEW_ChooseMounting_PAGE_View:
      return {
        ...chooseYourEarning,
        isViewChooseMountingPage: true,
        // selectedRow: payload
      };
    case VIEW_ViewEditChooseThisMounting:
      return {
        ...chooseYourEarning,
        isViewEditChooseYourSetting: true,
       // selectedRow: payload
      };
    case VIEW_HideEditChooseThisMounting:
      return {
        ...chooseYourEarning,
        isViewEditChooseYourSetting: false,
       //  selectedRow: payload
      };

    default:
      return chooseYourEarning;
  }
}
