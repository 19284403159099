import {
  VIEW_EARINIGCOMPLETE_PAGE,
  VIEW_EARINIGCOMPLETE_PAGE_Hide,
  VIEW_ChooseMounting_PAGE_Hide,
  VIEW_ChooseMounting_PAGE_View,
  VIEW_ViewEditChooseThisMounting,
  VIEW_HideEditChooseThisMounting
} from "./types";

export const handleCompleteEarningYourPendantPage = data => async dispatch => {
  try {
    dispatch({
      type: VIEW_EARINIGCOMPLETE_PAGE,
      payload: data
    });
  } catch (error) {}
};

export const handleHideCompleteEarningYourStudPage = data => async dispatch => {
  try {
    dispatch({
      type: VIEW_EARINIGCOMPLETE_PAGE_Hide,
      payload: data
    });
  } catch (error) {}
};

export const handleHideCompletethisMounting = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_ChooseMounting_PAGE_Hide,
      payload: data,
    });
  } catch (error) { }
};

export const handleViewCompletethisMounting = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_ChooseMounting_PAGE_View,
      payload: data,
    });
  } catch (error) { }
};

export const handleViewEditChooseThisMounting = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_ViewEditChooseThisMounting,
      payload: data,
    });
  } catch (error) { }
};


export const handleHideEditChooseThisMounting = (data) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_HideEditChooseThisMounting,
      payload: data,
    });
  } catch (error) { }
};
