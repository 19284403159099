import { httpApps } from "../helper/http-common";

class ChooseViewDetailsServices {
    PendantBuilderDropHint(inputData) {
        return httpApps.post("PendantBuilder/PendantBuilderDropHint", inputData);
    }
    PendantBuilderRequestMoreInfo(inputData) {
        return httpApps.post("PendantBuilder/PendantBuilderRequestMoreInfo", inputData);
    }
    PendantBuilderEmailToFriend(inputData) {
        return httpApps.post("PendantBuilder/PendantBuilderEmailToFriend", inputData);
    }
    PendantBuilderEmailToFriend(inputData) {
        return httpApps.post("PendantBuilder/PendantBuilderEmailToFriend", inputData);
    }
    PendantBuilderScheduleviewing(inputData) {
        return httpApps.post("PendantBuilder/PendantBuilderScheduleviewing", inputData);
    }
    GetDealerLocationData(inputData) {
        return httpApps.post("DiamondLink/GetDealerLocationData", inputData);
    }
    PendantBuilderLoadDiamonds(inputData) {
        return httpApps.post("PendantBuilder/PendantBuilderLoadDiamonds", inputData);
    }
    BindTimeDropDown(inputData) {
        return httpApps.post("studBuilder/BindTimeDropDown", inputData);
      }
    
}


export default new ChooseViewDetailsServices();
