import {
  VIEW_DETAILS_PAGE,
  HIDE_DETAILS_PAGE,
} from "../../actions/chooseYourSetting/types";

const initialState = {
  isViewDetails: false,
  selectedRow: null,
};

export default function handleChooseYourSetting(
  chooseYourSetting = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case VIEW_DETAILS_PAGE:
      return {
        ...chooseYourSetting,
        isViewDetails: true,
        selectedRow: payload,
      };
    case HIDE_DETAILS_PAGE:
      return {
        ...chooseYourSetting,
        isViewDetails: false,
        selectedRow: null,
      };

    default:
      return chooseYourSetting;
  }
}
