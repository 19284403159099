import React, { useEffect, useState, useLayoutEffect } from "react";
import { Spin } from "antd";
import ResetIcon from "../../assets/images/choose-setting/reset__icon.svg";
import selectYourDiamondService from "../../services/select-your-diamond.service";
import { useSelector, useDispatch } from "react-redux";
import colorPanelService from "../../services/color-panel.service";
import {
  handleResetMinedPage,
  handleResetLabPage,
  handleResetRequestPage,
  handleSetSaveSearchMinedTab,
  handleSetSwitchFilter,
  handleSetSaveSearchLabTab,
  handleSetSwitchLabFilter,
  handleSetDiamondActiveTab,
  handleComaparePageIds,
} from "../../actions/selectYourDiamond/selectYourDiamond";
import { NotificationManager } from "react-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import {
  handleSetPage,
} from "../../actions/PageNavigations/PageNavigations";

const SelectYourDiamond = ({ }) => {
  const navigate = useNavigate();
  const CompareIds = useSelector(
    (state) => state.selectYourDiamondReducer.compareIds
  );
  const search = useLocation().search;
  const isselectyourdiamond = new URLSearchParams(search).get(
    "isselectyourdiamond"
  );
  const isChange = new URLSearchParams(search).get("isChange");
  const [activeTab, setActiveTab] = useState("Mined");
  const [navigationData, setNavigationData] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [wrapperLoading, setWrapperLoading] = useState(false);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [key, setKey] = useState("Mined");

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const pageDetails = useSelector((state) => state.selectYourDiamondReducer);
  const dispatch = useDispatch();
  const dealerId = new URLSearchParams(search).get("DealerLink");
  const PageNavigations = useSelector((state) => state.pageNavigationReducer);

  const handleSaveSearchForPage = () => {
    if (activeTab == "Mined") {
      dispatch(handleSetSaveSearchMinedTab(true));
      dispatch(handleSetSwitchFilter());
    }
    if (activeTab == "Lab") {
      dispatch(handleSetSaveSearchLabTab(true));
      dispatch(handleSetSwitchLabFilter());
    }
    NotificationManager.success("Search Saved Successfully.");
  };

  const handleResetForPage = () => {
    if (activeTab == "Mined") {
      dispatch(handleResetMinedPage());
      dispatch(handleSetSaveSearchMinedTab(false));
    }
    if (activeTab == "Lab") {
      dispatch(handleResetLabPage());
      dispatch(handleSetSaveSearchLabTab(false));
    }
    if (activeTab == "Request") {
      dispatch(handleResetRequestPage());
    }
    NotificationManager.success("Search Reset Successfully.");
  };

  useEffect(() => {
    if (PageNavigations) {
      setKey(PageNavigations.key);
    }
  }, [PageNavigations]);

  useEffect(() => {
    if (dealerId) {
      handleGetNavigationData(dealerId);
      if (window.location.href.indexOf("Request") > -1) {
        setKey("Request");
      } else if (window.location.href.indexOf("Lab") > -1) {
        setKey("Lab");
      } else if (window.location.href.indexOf("Mined") > -1) {
        setKey("Mined");
      } else {
        setKey("Compare");
      }
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          handleGetNavigationData(loginDetails.dealerId);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (pageDetails) {
      if (pageDetails.activeDiamondTab) {
        setActiveTab(pageDetails.activeDiamondTab);
      }
    }
  }, [pageDetails.activeDiamondTab]);

  const handleGetNavigationData = async (id) => {
    let inputData = {
      dealerId: id.toString(),
    };
    try {
      await selectYourDiamondService
        .GetRingBuilderOpeningPage(inputData)
        .then((response) => {
          let responseData = response.data.responseData;
          if (responseData) {
            let navigationArray = [];
            if (responseData.labGrownData) {
              navigationArray.push(responseData.labGrownData[0]);
            }
            if (responseData.standardData) {
              navigationArray.push(responseData.standardData[0]);
            }
            if (responseData.requestDiamondData) {
              navigationArray.push(responseData.requestDiamondData[0]);
            }
            if (responseData.compareDiamondData) {
              navigationArray.push(responseData.compareDiamondData[0]);
              localStorage.setItem('compareTabData', JSON.stringify(responseData.compareDiamondData[0]))
            }

            let newArray = [];
            for (let i = 0; i < navigationArray.length + 1; i++) {
              newArray.push((i + 1).toString());
            }
            setOrderNumbers(newArray);

            let newminArr = navigationArray
              .filter((x) => x.setting == "True")
              .map((x) => {
                return x.order;
              });
            let val = Math.min(...newminArr);
            let newActiveTab = navigationArray
              .filter((item) => {
                return item.setting == "True" && item.order == val.toString();
              })[0]
              .name.toString();

            if (isChange == "true") {
              if (newActiveTab == "Mined") {
                navigate("/Mined" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Lab Grown") {
                navigate("/Lab" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Request") {
                navigate("/Request" + `?DealerLink=${dealerId}`);
              } else if (newActiveTab == "Compare") {
                navigate("/Compare" + `?DealerLink=${dealerId}`);
              } else {
                navigate("/Mined" + `?DealerLink=${dealerId}`);
              }
              dispatch(handleSetPage(newActiveTab));
              setKey(newActiveTab);
            }
            setNavigationData(navigationArray);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (dealerId) {
      handleGetColorsData(dealerId);
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          handleGetColorsData(loginDetails.dealerId);
        }
      }
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "PendantBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              let tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
              }
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  /* Color Individually Ends */

  function getTextStyle(name) {
    if (key == name) {
      return {
        color: "#fff",
        background: `${varColorObj.columnHeaderAccentColor
          ? varColorObj.columnHeaderAccentColor
          : "#1883b8"
          }`,
      };
    }
  }

  const handlTabChanges = (val) => {
    setKey(val);

    if (val == "Mined") {
      navigate("/Mined" + `?DealerLink=${dealerId}`);
    } else if (val == "Lab") {
      navigate("/Lab" + `?DealerLink=${dealerId}`);
    }
    else if (val == "Request") {
      navigate("/Request" + `?DealerLink=${dealerId}`);
    } else if (val == "Compare") {
      let compareIds = CompareIds.toString();
      navigate("/Compare" + `?DealerLink=${dealerId}&CompareIds=${compareIds}`);
    } else {
      navigate("/Mined" + `?DealerLink=${dealerId}`);
    }
    if (val !== "Compare") {
      dispatch(handleComaparePageIds(""));
    }
    dispatch(handleSetDiamondActiveTab(val));
  };

  return (
    <React.Fragment>
      {isselectyourdiamond != "false" ? (
        <Spin spinning={wrapperLoading}>
          <div className="row">
            <div className="col-md-12">
              <div className="prodetail__section">
                <div className="col-md-12">
                  <div className="prod__maindiv app__preview p-0 border-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="prodesc__div">
                          <div className="tright__btns">
                            {key == "Compare" || key == "Request" ? null : (
                              <p onClick={handleSaveSearchForPage}>
                                <img />
                                <span>Save Search</span>
                              </p>
                            )}
                            {key == "Compare" ? null : (
                              <p onClick={handleResetForPage}>
                                <img src={ResetIcon} />
                                <span>Reset</span>
                              </p>
                            )}
                          </div>
                          <div className="custom__tab">
                            <ul>
                              {orderNumbers.map((order) => {
                                return navigationData.map((tab) => {
                                  return (
                                    tab.setting == "True" &&
                                    order == tab.order && (
                                      <li
                                        style={getTextStyle(tab.name)}
                                        onClick={() =>
                                          handlTabChanges(tab.name)
                                        }
                                        title={tab.name}
                                      >
                                        <span>
                                          {tab.name == "Compare"
                                            ? key == "Compare"
                                              ? "Compare"
                                              : "Compare" +
                                              " " +
                                              "(" +
                                              CompareIds.length +
                                              ")"
                                            :
                                            tab.name}
                                        </span>
                                      </li>
                                    )
                                  );
                                });
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default SelectYourDiamond;
