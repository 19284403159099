let config = {
  AppsAPIURL: "https://jc2-apps-api.mgr.brainvire.net/api",
  AdminAPIURL: "https://jc2-admin-api.mgr.brainvire.net/api",
  DealerAPIURL: "https://jc2-dlr-api.mgr.brainvire.net/api",
};
// let config = {
//   AppsAPIURL: "https://jc2-apps-api.mgr.brainvire.net/api",
//   AdminAPIURL: "https://jc2-admin-api.mgr.brainvire.net/api"
// };
// let config = {
//   AppsAPIURL: "https://gem-appsapi-demo.brainvire.net/api"
// };

export default config;
