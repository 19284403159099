import React, { useState, useEffect, useLayoutEffect } from "react";
import printicon from "../../assets/images/icons/printicon.svg";
import { Select, Button, Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import ChooseViewDetailsServices from "../../services/choose-view-details.service";
import SelectYourDiamondService from "../../services/select-your-diamond.service";
import { Tabs, Tab } from "react-bootstrap";
import parse from "html-react-parser";
import colorPanelService from "../../services/color-panel.service";
import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";
import drophint from "../../assets/images/choose-setting/drophint.png";
import calender from "../../assets/images/choose-setting/calender.svg";
import emailicon from "../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../assets/images/choose-setting/infoblack.svg";
import facebookLive from "../../assets/images/facebook-like.jpg";
import DtlFacebook from "../../assets/images/fb__icon.svg";
import DtlTwitter from "../../assets/images/twitter__icon.svg";
import DtlPintrest from "../../assets/images/pintrest__icon.svg";
import DtlInstagram from "../../assets/images/instragm__icon.svg";
import completeYourRing from "../../services/complete-your-ring.service";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useLocation } from "react-router-dom";
import axios from "axios";

const initialDropHintInput = {
  name: "",
  email: "",
  recipientName: "",
  recipientEmail: "",
  giftReason: "",
  giftDeadline: "",
  personalMessage: "",
}

const initialDropHintInputVal = {
  nameval: "",
  emailval: "",
  recipientNameval: "",
  recipientEmailval: "",
  giftReasonval: "",
  giftDeadlineval: "",
  personalMessageval: "",
}

const initialRequestMoreInfoInput = {
  name: "",
  email: "",
  phoneNumber: "",
  contactPreference: "byEmail",
  personalMessage: "",
}

const initialRequestMoreInfoInputVal = {
  nameval: "",
  emailval: "",
  phoneNumberval: "",
  contactPreferenceval: "",
  personalMessageval: "",
}

const initialEmailFriendInput = {
  name: "",
  email: "",
  friendName: "",
  friendEmail: "",
  personalMessage: "",
}

const initialEmailFriendInputVal = {
  nameval: "",
  emailval: "",
  friendNameval: "",
  friendEmailval: "",
  personalMessageval: "",
}

const initialScheduleViewingInput = {
  name: "",
  email: "",
  phoneNumber: "",
  location: "",
  appointmentDate: "",
  appointmentTime: "",
  address: "",
  message: "",
}

const initialScheduleViewingInputVal = {
  nameval: "",
  emailVal: "",
  phoneNumberVal: "",
  locationVal: "",
  appointmentDateVal: "",
  appointmentTimeVal: "",
  addressVal: "",
  messageVal: "",
}

export const CompleteYourRing = ({ props }) => {
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerID");
  const DiamondId = new URLSearchParams(search).get("DiamondId");
  const StyleNumber = new URLSearchParams(search).get("StyleNumber");
  const GFInventoryID = new URLSearchParams(search).get("GFInventoryID");
  const isCompleteYourRing = new URLSearchParams(search).get(
    "iscompleteyourring"
  );
  const socialpageName = new URLSearchParams(search).get("pageName");
  const paramDealerLink = new URLSearchParams(search).get("dealerLink");
  const { Option } = Select;
  const chooseYourEarning = useSelector(
    (state) => state.handleChooseYourEarningStudSetting
  );
  const [dealerId, setDealerId] = useState("");
  const DealerIDForSelectedDiamond = localStorage.getItem("DealerIDForSelectedDiamond");
  const [mountDetails, setMountDetails] = useState({
    diamondvalue: "",
    diamondsku: "",
    diamondcarat: "",
    diamondcut: "",
    diamondcolor: "",
    diamondclarity: "",
    diamondpolish: "",
    diamondCertificate: "",
    mountingStyle: "",
    mountingHeader: "",
    mountingDescription: "",
    mountingValue: "",
    image: "",
    dealerID: "",
    addToCartLink: "",
    facbookUrl: "",
    facebookLikeUrl: "",
    twitterUrl: "",
    instagramUrl: "",
    pinterestUrl: "",
    cutGrade: "",
  });
  const [isModalVisibleFullscreen, setIsModalVisibleFullscreen] =
    useState(false);
  const handleCancelFullscreen = () => {
    setIsModalVisibleFullscreen(false);
  };
  const [settingItemDetailsData, setSettingItemDetailsData] = useState("");
  const [gfInventoryIdDetails, setGfInventoryIdDetails] = useState(0);
  const [diamondInfoDetails, setDiamondInfoDetails] = useState({});
  const [studBackingTypeList, setStudBackingTypeList] = useState([]);
  const [studMetalColorList, setStudMetalColorList] = useState([]);
  const [studMetalTypeList, setStudMetalTypeList] = useState([]);
  const [settingLoading, setSettingLoading] = useState("");
  const [diamondDetailsLoading, setDiamondDetailsLoading] = useState(false);
  const [showDealerInfo, setShowDealerInfo] = useState();
  const [dealerInfo, setDealerInfo] = useState(false);
  const [internalUseModalLoading, setInternalUseModalLoading] = useState(false);
  const [internalUseModal, setInternalUseModal] = useState(false);
  const [pinterestIcons, setPinterestIcons] = useState(false);
  const [twitterIcons, setTwitterIcons] = useState(false);
  const [callForPrice, setCallForPrice] = useState(false);
  const [instaGramIcons, setInstaGramIcons] = useState(false);
  const [facebookLikeIcons, setFacebookLikeIcons] = useState(false);
  const [addToCartIcons, setAddToCartIcons] = useState(false);
  const [scheduleHide, setScheduleHide] = useState(true);
  const [adressHide, setAdressHide] = useState(true);
  const [internalUseLinkHide, setInternalUseLinkHide] = useState(false);
  const [internalButtonSave, setInternalButtonSave] = useState(false);

  const [facebookIcons, setFacebookIcons] = useState(false);
  const [internalUseData, setInternalUseData] = useState({
    password: "",
  });

  const [loadingGrid, setLoadingGrid] = useState(false);

  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const Details = useSelector(
    (state) => state.selectYourDiamondReducer.selectedRow
  );


  // Modal Functions
  const [requestMoreModal, setRequestMoreModal] = useState(false);
  const [showDropModal, setShowDropModal] = useState(false);
  const [emailFriendModal, setEmailFriendModal] = useState(false);
  const [scheduleViewing, setOpenScheduleViewing] = useState(false);
  const [isModalVisible6, setIsModalVisible6] = useState(false);
  const [diamondDetails, setDiamondDetails] = useState("");
  const [jewelryId, setJewelryId] = useState("");
  const [completeEarringInputData, setCompleteEarringInputData] = useState({
    metalType: "",
    metalColor: "",
    backingType: "",
  });

  const [internalUseDataval, setInternalUseDataval] = useState({
    passwordval: "",
  });
  const [dropHintInputData, setDropHintInputData] = useState(initialDropHintInput);

  const [dropHintInputDataValidation, setDropHintInputDataValidation] =
    useState(initialDropHintInputVal);

  const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState(initialRequestMoreInfoInput);

  const [
    requestMoreInfoInputDataValidation,
    setRequestMoreInfoInputDataValidation,
  ] = useState(initialRequestMoreInfoInputVal);

  const [emailFriendInputData, setEmailFriendInputData] = useState(initialEmailFriendInput);

  const [emailFriendInputDataValidation, setEmailFriendInputDataValidation] =
    useState(initialEmailFriendInputVal);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [scheduleViewingInputData, setScheduleViewingInputData] = useState(initialScheduleViewingInput);

  const [
    scheduleViewingInputDataValidation,
    setScheduleViewingInputDataValidation,
  ] = useState(initialScheduleViewingInputVal);

  const [currencySymbol, setCurrencySymbol] = useState("");
  const [locationData, setLocationData] = useState({
    locationName: "",
    phone: "",
    emailID: "",
  });
  const [getTimedate, setGetTimeDate] = useState([]);
  const [locationDetails, setLocationDetails] = useState([]);

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });

  useLayoutEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          handleGetColorsData(loginDetails.dealerId);
        }
      }
    }
  }, [previewcolor.toogle]);

  const showModalFullscreen = () => {
    setIsModalVisibleFullscreen(true);
  };


  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "PendantBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              let tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */


  useEffect(() => {
    if (isCompleteYourRing == "True") {
      if (paramdealerId) {
        GetMountDetailsById(GFInventoryID, paramdealerId, StyleNumber);
        handleGetSocialDetails(paramdealerId);
        GetStudDropDownData(GFInventoryID, paramdealerId);
        setGfInventoryIdDetails(GFInventoryID);
        GetLocationLoadDetails(paramdealerId);
        handleGetDealerLocationData(paramdealerId, GFInventoryID);
        setDiamondDetails(GFInventoryID);
        setDealerId(paramdealerId);
        handleGetDiamondDetails(paramdealerId, DiamondId, socialpageName);
        setJewelryId(GFInventoryID);
      }
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          if (chooseYourEarning) {
            if (chooseYourEarning.isViewEarningPageDetails) {
              GetMountDetailsById(
                chooseYourEarning.selectedRow.GFInventoryID,
                loginDetails.dealerId,
                chooseYourEarning.selectedRow.StyleNumber
              );
              handleGetSocialDetails(loginDetails.dealerId);
              GetStudDropDownData(
                chooseYourEarning.selectedRow.GFInventoryID,
                loginDetails.dealerId
              );
              setGfInventoryIdDetails(
                chooseYourEarning.selectedRow.GFInventoryID
              );
              GetLocationLoadDetails(loginDetails.dealerId);
              handleGetDealerLocationData(
                loginDetails.dealerId,
                chooseYourEarning.selectedRow.GFInventoryID
              );
              setDiamondDetails(chooseYourEarning.selectedRow.GFInventoryID);
              setDealerId(loginDetails.dealerId);
            }
          }
          if (Details) {
            if (Details.intDiamondId) {
              handleGetDiamondDetails(
                loginDetails.dealerId,
                Details.intDiamondId
              );
              setJewelryId(Details.intDiamondId);
            }
          }
        }
      }
    }
  }, []);

  const closeRequestMoreModal = () => {
    setRequestMoreModal(false);
    setRequestMoreInfoInputData(initialRequestMoreInfoInput);
    setRequestMoreInfoInputDataValidation(initialRequestMoreInfoInputVal);
  };

  const handleGetDateandTime = (id, theme, appoint, location) => {
    try {
      let inputData = {
        dealerLink: id,
        themeName: theme,
        appointDate: appoint,
        locationID: location.toString(),
      };
      ChooseViewDetailsServices.BindTimeDropDown(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message == "Success") {
            setGetTimeDate(responseData.responseData.HTMLDataTime.data);
          } else {
            setGetTimeDate([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetLocationLoadDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    try {
      SelectYourDiamondService.GetLocationDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message == "Success") {
            setLocationDetails(responseData.Table);
          } else {
            setLocationDetails([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const hideDropHintModal = () => {
    setShowDropModal(false);
    setDropHintInputData(initialDropHintInput);
    setDropHintInputDataValidation(initialDropHintInputVal);
  };

  const handleSubmitRequestMoreInfo = () => {
    handleSaveRequestMoreInfo();
  };

  const closeEmailFriendModal = () => {
    setEmailFriendModal(false);
    setEmailFriendInputData(initialEmailFriendInput);
    setEmailFriendInputDataValidation(initialEmailFriendInputVal);
  };

  const handleSubmitSheduleViewing = () => {
    handleSaveScheduleViewing();
  };
  const handleSubmitDropHint = () => {
    handleSaveDropHint();
  };

  const closeScheduleViewingModal = () => {
    setOpenScheduleViewing(false);
    setScheduleViewingInputData(initialScheduleViewingInput);
    setScheduleViewingInputDataValidation(initialScheduleViewingInputVal);
  };

  const handleCancel6 = () => {
    setIsModalVisible6(false);
  };

  const handleDropHintChange = (e, name, type) => {
    setDropHintInputData({
      ...dropHintInputData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
    if (name == "email") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        emailval: "",
      }));
    }
    if (name == "recipientName") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        recipientNameval: "",
      }));
    }
    if (name == "recipientEmail") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        recipientEmailval: "",
      }));
    }
    if (name == "giftReason") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        giftReasonval: "",
      }));
    }
    if (name == "personalMessage") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        personalMessageval: "",
      }));
    }
    if (name == "giftDeadline") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        giftDeadlineval: "",
      }));
    }
  };

  const handleRequestMoreInfoChange = (e, name, type) => {
    setRequestMoreInfoInputData({
      ...requestMoreInfoInputData,
      [name]: e.target.value,
    });

    if (name == "name") {
      setRequestMoreInfoInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
    if (name == "email") {
      setRequestMoreInfoInputDataValidation((prevdata) => ({
        ...prevdata,
        emailval: "",
      }));
    }
    if (name == "phoneNumber") {
      setRequestMoreInfoInputDataValidation((prevdata) => ({
        ...prevdata,
        phoneNumberval: "",
      }));
    }
  };

  const handleEmailFriendChange = (e, name) => {
    setEmailFriendInputData({
      ...emailFriendInputData,
      [name]: e.target.value,
    });

    if (name == "name") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
    if (name == "email") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        emailval: "",
      }));
    }
    if (name == "friendName") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        friendNameval: "",
      }));
    }

    if (name == "friendEmail") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        friendEmailval: "",
      }));
    }

    if (name == "personalMessage") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        personalMessageval: "",
      }));
    }
  };

  const handleSubmitEmailFriend = () => {
    handleSaveEmailFriend();
  };

  const handleScheduleViewingChange = (e, name, type) => {
    if (type == "select") {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e,
      });

      if (name == "location") {
        handleGetDateandTime(
          dealerId,
          "",
          scheduleViewingInputData.appointmentDate,
          e
        );
      }
    } else {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e.target.value,
      });

      if (name == "appointmentDate") {
        handleGetDateandTime(
          dealerId,
          "",
          moment(e.target.value).format("MM/DD/YYYY"),
          scheduleViewingInputData.location
        );
      }
    }

    if (name == "name") {
      setScheduleViewingInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
  };

  const handleValidationPassworsInfo = () => {
    const { password } = internalUseData;

    const internalUseDataval = {
      passwordval: "",
    };
    let isValid = true;

    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})"
    );

    if (!password.trim()) {
      isValid = false;
      internalUseDataval.passwordval = "Password is Compulsory";
    } else if (!password.match(strongRegex)) {
      isValid = false;
      internalUseDataval.passwordval =
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character";
    } else {
      internalUseDataval.passwordval = "";
    }
    setInternalUseDataval(internalUseDataval);
    return isValid;
  };

  const handleValidationDrop = () => {
    const {
      name,
      email,
      recipientName,
      recipientEmail,
      giftReason,
      personalMessage,
      giftDeadline,
    } = dropHintInputData;

    const dropHintInputDataValidation = {
      nameval: "",
      emailval: "",
      recipientNameval: "",
      recipientEmailval: "",
      giftReasonval: "",
      personalMessageval: "",
      giftDeadlineval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      dropHintInputDataValidation.nameval = "Name is Compulsory";
    } else {
      dropHintInputDataValidation.nameval = "";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      dropHintInputDataValidation.emailval = "Email is Compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      dropHintInputDataValidation.emailval = "Email is Invalid";
    } else {
      dropHintInputDataValidation.emailval = "";
    }

    if (!recipientName.trim()) {
      isValid = false;
      dropHintInputDataValidation.recipientNameval =
        "Recipient's Name is Compulsory";
    } else {
      dropHintInputDataValidation.recipientNameval = "";
    }

    if (!recipientEmail.trim()) {
      isValid = false;
      dropHintInputDataValidation.recipientEmailval =
        "Recipient's E-Mail is Compulsory";
    } else if (!recipientEmail.match(validRegex)) {
      isValid = false;
      dropHintInputDataValidation.recipientEmailval =
        "Recipient's E-Mail is Invalid";
    } else {
      dropHintInputDataValidation.recipientEmailval = "";
    }

    if (!giftReason.trim()) {
      isValid = false;
      dropHintInputDataValidation.giftReasonval = "Reason is Compulsory";
    } else {
      dropHintInputDataValidation.giftReasonval = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      dropHintInputDataValidation.personalMessageval =
        "Personal Message is Compulsory";
    } else {
      dropHintInputDataValidation.personalMessageval = "";
    }

    if (!giftDeadline.trim()) {
      isValid = false;
      dropHintInputDataValidation.giftDeadlineval =
        "Gift Deadline is Compulsory";
    } else {
      dropHintInputDataValidation.giftDeadlineval = "";
    }

    setDropHintInputDataValidation(dropHintInputDataValidation);
    return isValid;
  };

  const handleGetDealerLocationData = (id, retailerId) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        myRetailerID: retailerId,
      };

      SelectYourDiamondService.GetDealerLocationData(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData[0];
          let newRe = response.data.responseData[0];

          if (message == "Success") {
            if (responseData) {
              if (Object.keys(newRe).length > 0) {
                setLocationData({
                  locationName: responseData.locationName,
                  phone: responseData.phone,
                  emailID: responseData.emailID,
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleValidationReuestInfo = () => {
    const { name, email, phoneNumber } = requestMoreInfoInputData;

    const requestMoreInfoInputDataValidation = {
      nameval: "",
      emailval: "",
      phoneNumberval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      requestMoreInfoInputDataValidation.nameval = "Name is Compulsory";
    } else {
      requestMoreInfoInputDataValidation.nameval = "";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      requestMoreInfoInputDataValidation.emailval = "Email is Compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      requestMoreInfoInputDataValidation.emailval = "Email is Invalid";
    } else {
      requestMoreInfoInputDataValidation.emailval = "";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      requestMoreInfoInputDataValidation.phoneNumberval =
        "Phone Number is Compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      requestMoreInfoInputDataValidation.phoneNumberval = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      requestMoreInfoInputDataValidation.phoneNumberval =
        "Phone number is invalid";
    } else {
      requestMoreInfoInputDataValidation.phoneNumberval = "";
    }
    setRequestMoreInfoInputDataValidation(requestMoreInfoInputDataValidation);
    return isValid;
  };

  const handleValidationSchedule = () => {
    const {
      name,
      email,
      phoneNumber,
      location,
      appointmentDate,
      appointmentTime,
      address,
      message,
    } = scheduleViewingInputData;

    const scheduleViewingInputDataValidation = {
      nameval: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      scheduleViewingInputDataValidation.nameval = "Name is Compulsory";
    } else {
      scheduleViewingInputDataValidation.nameval = "";
    }
    if (!location) {
      isValid = false;
      scheduleViewingInputDataValidation.locationVal = "Location is Compulsory";
    }

    if (!appointmentDate) {
      isValid = false;
      scheduleViewingInputDataValidation.appointmentDateVal =
        "Appointment Date is Compulsory";
    }

    if (!appointmentTime) {
      isValid = false;
      scheduleViewingInputDataValidation.appointmentTimeVal =
        "Appointment Time is Compulsory";
    }

    if (!message) {
      isValid = false;
      scheduleViewingInputDataValidation.messageVal = "Message is Compulsory";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      isValid = false;
      scheduleViewingInputDataValidation.emailVal =
        "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      scheduleViewingInputDataValidation.emailVal = "Email address is invalid";
    } else {
      scheduleViewingInputDataValidation.emailVal = "";
    }
    if (!phoneNumber) {
      isValid = false;
      scheduleViewingInputDataValidation.phoneNumberVal =
        "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      scheduleViewingInputDataValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      scheduleViewingInputDataValidation.phoneNumberVal =
        "Phone number is invalid";
    } else {
      scheduleViewingInputDataValidation.phoneNumberVal = "";
    }

    setScheduleViewingInputDataValidation(scheduleViewingInputDataValidation);
    return isValid;
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleSaveDropHint = async () => {
    let isValid = handleValidationDrop();

    if (!isValid) {
      setShowDropModal(true);
      return false;
    }

    let inputData = {
      loadFromMasterlink: true,
      strDiamondLinkURL: "",
      dealerID: dealerId,
      hintYourEmail: dropHintInputData.email,
      hintYourName: dropHintInputData.name,
      hintRecptEmail: dropHintInputData.recipientEmail,
      hintRecptName: dropHintInputData.recipientName,
      hintRecptReason: dropHintInputData.giftReason,
      hintRecpMessage: dropHintInputData.personalMessage,
      hintRecptGiftDeadline: dropHintInputData.giftDeadline,
      sid: "",
      did: jewelryId,
      shape: "",
      ctw: "",
      step: "",
      themeName: "WhiteTheme",
      viewItemURL: window.location.href.toString(),
      type: "Jewelry",
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderDropHint(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind."
            );
            setShowDropModal(false);
            setButtonLoading(false);
            setDropHintInputData(initialDropHintInput);
          } else {
            NotificationManager.error(
              "Your request has not been sent to GemFind."
            );
            setShowDropModal(false);
            setButtonLoading(false);
            setDropHintInputData(initialDropHintInput);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            "Your request has not been sent to GemFind."
          );
          setShowDropModal(false);
          setButtonLoading(false);
          setDropHintInputData(initialDropHintInput);

          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Your request has not been sent to GemFind.");
      setShowDropModal(false);
      setButtonLoading(false);
      setDropHintInputData(initialDropHintInput);

      console.log(error);
    }
  };

  const handlepasswordMoreInfoChange = (e, name, type) => {
    setInternalUseData({
      ...internalUseData,
      [name]: e.target.value,
    });

    if (name == "password") {
      setInternalUseDataval((prevdata) => ({
        ...prevdata,
        passwordval: "",
      }));
    }
  };
  function calcPriceForMailAPI() {
    let price = 0
    if (callForPrice) {
      price = "Call For Price"
    } else {
      if (mountDetails.diamondvalue) {
        let numVal = parseInt(mountDetails.diamondvalue);
        if (numVal > 0) price += numVal;
      }
      if (mountDetails.mountingValue) {
        let numVal = parseInt(mountDetails.mountingValue);
        if (numVal > 0) price += numVal;
      }
      if (price == 0) {
        price = "Call For Price"
      } else {
        price = price.toString()
      }
    }
    return price
  }
  const handleSaveRequestMoreInfo = async () => {
    let isValid = handleValidationReuestInfo();

    if (!isValid) {
      setRequestMoreModal(true);
      return false;
    }

    let inputData = {
      dealerID: dealerId,
      email: requestMoreInfoInputData.email,
      phone: requestMoreInfoInputData.phoneNumber,
      byEmail:
        requestMoreInfoInputData.contactPreference == "byEmail"
          ? true
          : false,
      comments: requestMoreInfoInputData.personalMessage,
      name: requestMoreInfoInputData.name,
      did: DiamondId + "," + GFInventoryID,
      retailerID: DealerIDForSelectedDiamond,
      type: "Jewelry",
      viewThisItemURL: window.location.href,
      "price": calcPriceForMailAPI(),
        MetalType : completeEarringInputData?.metalType,
        MetalColor : completeEarringInputData?.metalColor,
        DiamondPriceWithCurrency : currencySymbol + parseInt(
          mountDetails?.diamondvalue
        )
          .toFixed()
          .replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          ).toString(),
        SettingPriceWithCurrency : currencySymbol + parseInt(
          mountDetails?.mountingValue
        )
          .toFixed()
          .replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          ).toString()

    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderRequestMoreInfo(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setButtonLoading(false);
            setRequestMoreModal(false);
            setRequestMoreInfoInputData(initialRequestMoreInfoInput);
          } else {
            NotificationManager.error(
              "Your request has not been sent to GemFind."
            );
            setButtonLoading(false);
            setRequestMoreModal(false);
            setRequestMoreInfoInputData(initialRequestMoreInfoInput);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            "Your request has not been sent to GemFind."
          );
          setButtonLoading(false);
          setRequestMoreModal(false);
          setRequestMoreInfoInputData(initialRequestMoreInfoInput);
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Your request has not been sent to GemFind.");
      setButtonLoading(false);
      setRequestMoreModal(false);
      setRequestMoreInfoInputData(initialRequestMoreInfoInput);
      console.log(error);
    }
  };

  const handleValidationEmailFriend = () => {
    const { name, email, friendName, friendEmail, personalMessage } =
      emailFriendInputData;

    const emailFriendInputDataValidation = {
      nameval: "",
      emailval: "",
      friendNameval: "",
      friendEmailval: "",
      personalMessageval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      emailFriendInputDataValidation.nameval = "Name is Compulsory";
    } else {
      emailFriendInputDataValidation.nameval = "";
    }

    let validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      emailFriendInputDataValidation.emailval = "Email is Compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      emailFriendInputDataValidation.emailval = "Email is Invalid";
    } else {
      emailFriendInputDataValidation.emailval = "";
    }

    if (!friendName.trim()) {
      isValid = false;
      emailFriendInputDataValidation.friendNameval =
        "Friend's Name is Compulsory";
    } else {
      emailFriendInputDataValidation.friendNameval = "";
    }

    if (!friendEmail.trim()) {
      isValid = false;
      emailFriendInputDataValidation.friendEmailval =
        "Friend's E-Mail is Compulsory";
    } else if (!friendEmail.match(validRegex)) {
      isValid = false;
      emailFriendInputDataValidation.friendEmailval =
        "Friend's E-Mail is Invalid";
    } else {
      emailFriendInputDataValidation.friendEmailval = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      emailFriendInputDataValidation.personalMessageval =
        "Personal Message is Compulsory";
    } else {
      emailFriendInputDataValidation.personalMessageval = "";
    }
    setEmailFriendInputDataValidation(emailFriendInputDataValidation);
    return isValid;
  };

  const handleSaveEmailFriend = async () => {
    let isValid = handleValidationEmailFriend();

    if (!isValid) {
      setEmailFriendModal(true);
      return false;
    }

    let inputData = {
      dealerID: dealerId,
      yourEmail: emailFriendInputData.email,
      tellFriendComment: emailFriendInputData.personalMessage,
      yourName: emailFriendInputData.name,
      yourFriendEmail: emailFriendInputData.friendEmail,
      yourFriendName: emailFriendInputData.friendName,
      did: DiamondId + "," + GFInventoryID,
      retailerID: DealerIDForSelectedDiamond,
      byEmail: true,
      viewThisItemURL: window.location.href,
      type: "Jewelry",
      "price": calcPriceForMailAPI(),
      priceWithCurrency: currencySymbol + parseInt(
        mountDetails.diamondvalue
      ).toFixed()
        .replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ).toString(),
        MetalType : completeEarringInputData?.metalType,
        MetalColor : completeEarringInputData?.metalColor,
        SettingPriceWithCurrency : currencySymbol + parseInt(
          mountDetails?.mountingValue
        )
          .toFixed()
          .replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          ).toString()
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderEmailToFriend(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            NotificationManager.success(
              "An email has been sent to your friend,Thanks for sharing!"
            );
            setButtonLoading(false);
            setEmailFriendModal(false);
            setEmailFriendInputData(initialEmailFriendInput);
          } else {
            NotificationManager.error(
              "An email has been not sent to your friend."
            );
            setButtonLoading(false);
            setEmailFriendModal(false);
            setEmailFriendInputData(initialEmailFriendInput);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            "An email has been not sent to your friend."
          );
          setButtonLoading(false);
          setEmailFriendModal(false);
          setEmailFriendInputData(initialEmailFriendInput);
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("An email has been not sent to your friend.");
      setButtonLoading(false);
      setEmailFriendModal(false);
      setEmailFriendInputData(initialEmailFriendInput);
      console.log(error);
    }
  };

  const handleSaveScheduleViewing = async () => {
    let isValid = handleValidationSchedule();

    if (!isValid) {
      setOpenScheduleViewing(true);
      return false;
    }
    let valuePrice= parseInt(mountDetails.diamondvalue);
    let actualValue = `${currencySymbol}${valuePrice}`;
    let inputData = {
      retailerID: DealerIDForSelectedDiamond,
      dealerID: dealerId,
      emailChk: scheduleViewingInputData.email,
      schedulPnone: scheduleViewingInputData.phoneNumber,
      appntMsg: scheduleViewingInputData.message,
      schedulName: scheduleViewingInputData.name,
      appntDate: scheduleViewingInputData.appointmentDate,
      hndAppntTime: scheduleViewingInputData.appointmentTime,
      did: DiamondId,
      shape: "",
      ctw: "",
      strDiamondLinkURL: "",
      step: "",
      themeName: "",
      loadFromMasterlink: true,
      viewThisItemURL: window.location.href,
      type: "Jewelry",
      location: scheduleViewingInputData.location.toString(),
      SettingID: GFInventoryID ?? chooseYourEarning.selectedRow.GFInventoryID,
      price: callForPrice ? " Call For Price" : (parseInt(mountDetails.diamondvalue)).toString() || "",
      diamondPriceWithCurrency: callForPrice ? " Call For Price" : actualValue.toString() || "",
      metalType : completeEarringInputData.metalType,
      priceWithCurrency: currencySymbol + parseInt(
        mountDetails.mountingValue
      )
        .toFixed()
        .replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ).toString(),
        metalColor : completeEarringInputData.metalColor ? completeEarringInputData.metalColor  : ""
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderScheduleviewing(inputData)
        .then((response) => {
          let msg = response.data.message;
          if (msg == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind."
            );
            setButtonLoading(false);
            setOpenScheduleViewing(false);
            setScheduleViewingInputData(initialScheduleViewingInput);
          } else {
            NotificationManager.error(
              "Your request has not been sent to GemFind."
            );
            setButtonLoading(false);
            setOpenScheduleViewing(false);
            setScheduleViewingInputData(initialScheduleViewingInput);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            "Your request has not been sent to GemFind."
          );
          setButtonLoading(false);
          setOpenScheduleViewing(false);
          setScheduleViewingInputData(initialScheduleViewingInput);
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Your request has not been sent to GemFind.");
      setButtonLoading(false);
      setOpenScheduleViewing(false);
      setScheduleViewingInputData(initialScheduleViewingInput);
      console.log(error);
    }
  };

  const showModal1 = () => {
    setRequestMoreModal(true);
  };

  const showModal2 = () => {
    setShowDropModal(true);
  };
  const showModal3 = () => {
    setEmailFriendModal(true);
  };
  const showModal5 = () => {
    setOpenScheduleViewing(true);
  };

  const handleOpenInternalModal = () => {
    setInternalUseModal(true);
  };

  const handleSubmitInternalDataHide = () => {
    setInternalButtonSave(false);
    setInternalUseModal(false);
    setShowDealerInfo(false);
  };

  const HandleCallForInternal = () => {
    {
      internalButtonSave
        ? handleSubmitInternalDataHide()
        : handleSubmitInternalData();
    }
  };

  const handleSubmitInternalData = () => {
    handleGetDealerInformation();
    setInternalButtonSave(true);
    setShowDealerInfo(false);
  };

  const handleShareIconsView = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank");
    } else if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  const handleGetDealerInformation = () => {
    let isValid = handleValidationPassworsInfo();

    if (!isValid) {
      setInternalUseModal(true);
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        diamondDealerID: diamondInfoDetails.dealerID,
        password: internalUseData.password,
        diamondID: diamondDetails,
      };
      setInternalUseModalLoading(true);
      SelectYourDiamondService.GetDealerInformation(inputData)
        .then((response) => {
          let message = response.data.responseData.passwordcheck;
          let dealerInfo = response.data.responseData.getDiamondInformation;
          if (message == false) {
            NotificationManager.error("Kindly Enter valid Details");
            setInternalUseModalLoading(false);
            setShowDealerInfo(false);
            setInternalButtonSave(false);
          } else {
            setShowDealerInfo(true);
            setDealerInfo(dealerInfo);
            setInternalUseModalLoading(false);
            setInternalUseData({
              password: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setInternalUseModalLoading(false);
          setInternalUseData({
            password: "",
          });
        });
    } catch (error) {
      console.log(error);
      setInternalUseModalLoading(false);
      setInternalUseData({
        password: "",
      });
    }
  };

  const handleCancelInternalModal = () => {
    setInternalUseModal(false);
    setShowDealerInfo(false);

    setInternalUseData({
      password: "",
    });
    setInternalUseDataval({
      passwordval: "",
    });
    setInternalButtonSave(false);
  };
  const handleGetSocialDetails = (id) => {
    try {
      let inputData = {
        dealerID: parseInt(id),
      };
      SelectYourDiamondService.GetsPendantBuilderOptiondetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData.length > 0) {
              setPinterestIcons(responseData[0].showPinterestShare);
              setTwitterIcons(responseData[0].showTwitterShare);
              setFacebookIcons(responseData[0].showFacebookShare);
              setScheduleHide(responseData[0].showScheduleView);
              setAdressHide(responseData[0].showAddresses);
              setInstaGramIcons(responseData[0].showInstagramShare);
              setFacebookLikeIcons(responseData[0].showFacebookLike);
              setAddToCartIcons(responseData[0].showAddToCartButtonPendant);
              setInternalUseLinkHide(responseData[0].internalLinkPB);
              setCallForPrice(responseData[0].callForPricependant);
              setMountDetails((prevState) => ({
                ...prevState,
                addToCartLink: responseData[0].diamondpendentshoppingurl,
              }));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetStudDropDownData = async (id, dealerId) => {
    let inputData = {
      gfInventoryID: id,
      dealerId: dealerId,
    };
    setLoadingGrid(true);
    try {
      completeYourRing
        .GetStudDropDownData(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;

          if ((msg == "Success")) {
            setStudBackingTypeList(responseData.studBackingTypelst);
            setStudMetalColorList(responseData.studMetalColorlst);
            setStudMetalTypeList(responseData.studMetalTypelst);
            setLoadingGrid(false);
          } else {
            setStudBackingTypeList([]);
            setStudMetalColorList([]);
            setStudMetalTypeList([]);
            setLoadingGrid(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingGrid(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingGrid(false);
    }
  };

  const handleAddToCartView = () => {
    let domainUrl = mountDetails.addToCartLink.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/i
    );
    if (domainUrl && domainUrl.length > 1) {
      if (mountDetails.addToCartLink.includes("https")) {
        window.open(mountDetails.addToCartLink, "_blank");
      } else if (mountDetails.addToCartLink.includes("http")) {
        window.open(mountDetails.addToCartLink, "_blank");
      } else {
        window.open("https://" + mountDetails.addToCartLink, "_blank");
      }
    } else {
      NotificationManager.error("Please Add Shopping Cart Url");
    }
  };

  const handleGetDiamondDetails = (id, diamondId, socialpageName) => {
    try {
      let inputData = {
        did: diamondId,
        dealerID: id,
        countryTaxRate: "77",
        type: socialpageName,
      };
      setDiamondDetailsLoading(true);
      SelectYourDiamondService.GetRingBuilderViewDetailsMineLabDiamond(
        inputData
      )
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          let newRe = response.data.responseData;

          if (message == "Success") {
            if (Object.keys(newRe).length > 0) {
              setMountDetails((prevState) => ({
                ...prevState,
                diamondvalue: responseData.fltPrice,
                diamondsku: responseData.skuNo,
                diamondcarat: responseData.carat,
                diamondcut: responseData.cut,
                diamondcolor: responseData.color,
                diamondclarity: responseData.clarity,
                diamondpolish: responseData.polish,
                diamondCertificate: responseData.certificate,
                facbookUrl: responseData.faceBookLink,
                facebookLikeUrl: responseData.faceBookLike,
                twitterUrl: responseData.twitterLink,
                instagramUrl: responseData.instaLink,
                pinterestUrl: responseData.pinterestLink,
                cutGrade: responseData.cutGrade,
              }));
            }
            setDiamondDetailsLoading(false);
          } else {
            setDiamondDetailsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setDiamondDetailsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setDiamondDetailsLoading(false);
    }
  };

  const GetMountDetailsById = async (id, dealerID, styleNumber) => {
    let inputData = {
      gInventoryID: id,
      dealerId: dealerID,
      styleNumber: styleNumber,
      dealerLink: paramDealerLink.toString(),
    };
    setLoadingGrid(true);
    try {
      completeYourRing
        .LoadSettingsDetails(inputData)
        .then((response) => {
          let responseData = response.data.responseData;
          let newRe = response.data.responseData;

          if (Object.keys(newRe).length > 0) {
            if (responseData.metalColor.length > 0) {
              const color = responseData.metalColor;
              const colorsplit = color.split(",");
              setCompleteEarringInputData((prevState) => ({
                ...prevState,
                metalColor: colorsplit[0],
              }));
            }
            if (responseData.metalType.length > 0) {
              const metal = responseData.metalType;
              const metalsplit = metal.split(",");
              setCompleteEarringInputData((prevState) => ({
                ...prevState,
                metalType: metalsplit[0],
              }));
            }

            let arr = [];
            let obj = {};
            obj["original"] = responseData.imagePath;
            obj["thumbnail"] = responseData.imagePath;
            arr.push(obj);

            setMountDetails((prevState) => ({
              ...prevState,
              mountingStyle: responseData.styleNumber,
              mountingHeader: responseData.settingName,
              mountingDescription: responseData.description,
              mountingValue: responseData.settingPrice,
              image: responseData.imagePath,
            }));
            setLoadingGrid(false);
            getIpData(responseData, dealerID);
            setCurrencySymbol(responseData.currencySign);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingGrid(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingGrid(false);
    }
  };

  const getIpData = async (responseData, id) => {
    const diamondData = responseData;
    const res = await axios.get("https://geolocation-db.com/json/");
    handleProductTracking(diamondData, res.data.IPv4, id);
  };

  const handleProductTracking = async (res, ip, id) => {
    try {
      let inputData = {
        dealerID: paramdealerId.toString(),
        vendorID: res.manuID.toString(),
        gfInventoryID: GFInventoryID.toString(),
        url: window.location.href,
        styleNumber: res.styleNumber.toString(),
        dealerStockNumber: "",
        retailerStockNumber: "",
        metalType: res.metalType.toString(),
        metalColor: res.metalColor.toString(),
        pricetype: "",
        vendorPrice: "",
        userIPAddress: ip,
        category: "",
        price: res.settingPrice.toString(),
        vendorName: res.settingDealerCompany.toString(),
        apiKey: "",
        diamondID: "",
      };
      SelectYourDiamondService.ProductTracking(inputData)
        .then((response) => { })
        .catch((error) => { });
    } catch (error) { }
  };

  const handleCompleteEarringInputChange = (e, name) => {
    setCompleteEarringInputData({
      ...completeEarringInputData,
      [name]: e,
    });

    if (name == "metalType") {
      GetStudDropDownOnchangeData(
        "All",
        "1",
        mountDetails.dealerID,
        gfInventoryIdDetails,
        e,
        completeEarringInputData.metalColor,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "113",
        ""
      );
      setCompleteEarringInputData((prevState) => ({
        ...prevState,
        metalType: e,
      }));
    }
    if (name == "metalColor") {
      GetStudDropDownOnchangeData(
        "All",
        "1",
        mountDetails.dealerID,
        gfInventoryIdDetails,
        completeEarringInputData.metalType,
        e,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "113",
        ""
      );
      setCompleteEarringInputData((prevState) => ({
        ...prevState,
        metalColor: e,
      }));
    }
  };

  const GetStudDropDownOnchangeData = async (
    bind,
    all,
    dealer,
    product,
    configmetal,
    configcolor,
    configdicolor,
    configsttype,
    configno,
    configgemsto,
    configcarat,
    configdiamondqua,
    configpetype,
    configpeshape,
    configpesize,
    configpequality,
    configwidth,
    configthick,
    isvalue,
    jewcategory,
    countrytax
  ) => {
    let inputData = {
      bindWhich: bind,
      all: all,
      dealerID: dealerId,
      productID: product,
      configMetalType: configmetal,
      configMetalColor: configcolor,
      configDiamondColor: configdicolor,
      configGemstoneType: configsttype,
      configNoOfGemstones: configno,
      configGemstoneShape: configgemsto,
      configCaratWeight: configcarat,
      configDiamondQuality: configdiamondqua,
      configPearlType: configpetype,
      configPearlShape: configpeshape,
      configPearlSize: configpesize,
      configPearlQuality: configpequality,
      configWidth: configwidth,
      configThickness: configthick,
      iValue: isvalue,
      jewelryCategory: jewcategory,
      countryTaxRate: countrytax,
    };
    setLoadingGrid(true);
    try {
      completeYourRing
        .ChangeProductConfigurationPend(inputData)
        .then((response) => {
          let responseData = response.data.responseData;
          let newRe =
            responseData.changeProductConfigurationStudProdGemStoneProdDtls;

          if (Object.keys(newRe).length > 0) {
            let arr = [];
            let obj = {};
            obj["original"] = newRe.imagePath;
            obj["thumbnail"] = newRe.imagePath;
            arr.push(obj);
            setMountDetails((prevState) => ({
              ...prevState,
              mountingStyle:
                responseData.changeProductConfigurationStudProdGemStoneProdDtls
                  .styleNo,
              mountingHeader:
                responseData.changeProductConfigurationStudProdGemStoneProdDtls
                  .productName,
              mountingDescription:
                responseData.changeProductConfigurationStudProdGemStoneProdDtls
                  .productDescription,
              mountingValue:
                responseData.changeProductConfigurationStudProdGemStoneProdDtls
                  .finalPrice,
              image:
                responseData.changeProductConfigurationStudProdGemStoneProdDtls
                  .imagePath,
            }));

            setLoadingGrid(false);
          } else {
            setLoadingGrid(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingGrid(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingGrid(false);
    }
  };

  function handlePrintDiamondList() {
    window.print();
  }

  return (
    <React.Fragment>
      <Wrapper>
        <div className="allprod_section jwlsrch__section app__preview choose__setting">
          <Spin spinning={loadingGrid}>
            <div className="row">
              <div className="col-md-12">
                <div className="prodetail__section">
                  <div className="form__fields">
                    <div className="col-md-12">
                      <div
                        className="prod__maindiv app__preview p-0 border-0"
                        id="printsection"
                      >
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-md-12">
                                <p className="mb-0">
                                  Pendant Completed ... This Looks Amazing!
                                </p>
                              </div>
                              <div className="col-lg-12">
                                <div className="thimage__slider big__img">
                                  <div>
                                    <img
                                      src={
                                        mountDetails.image
                                          ? mountDetails.image
                                          : ""
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div
                                    className="video__icon fullscreen__icon"
                                    onClick={showModalFullscreen}
                                  >
                                    <i
                                      class="fa fa-arrows-alt"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="price__block text-center">
                                  <h4 className="subheading">
                                    {callForPrice ? (
                                      <span>Call For Price</span>
                                    ) : (
                                      <span>
                                        {mountDetails.diamondvalue == 0
                                          ? ""
                                          : currencySymbol}
                                        {mountDetails.diamondvalue == 0 &&
                                          mountDetails.mountingValue == 0
                                          ? "Call For Price"
                                          : (
                                            parseInt(
                                              mountDetails.diamondvalue
                                            ) +
                                            parseInt(
                                              mountDetails.mountingValue
                                            )
                                          )
                                            .toFixed()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}{" "}
                                      </span>
                                    )}
                                  </h4>
                                  {mountDetails.diamondvalue == "0" ||
                                    mountDetails.mountingValue == "0" ||
                                    callForPrice ||
                                    mountDetails.diamondvalue == "" ||
                                    mountDetails.mountingValue == "" ? (
                                    ""
                                  ) : (
                                    <div>
                                      {addToCartIcons ? (
                                        <button
                                          className="primary-btn"
                                          onClick={handleAddToCartView}
                                        >
                                          Add To Cart
                                        </button>
                                      ) : null}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {internalUseLinkHide ? (
                                <span
                                  className="font__bold mr-2 mt-3 linkText"
                                  onClick={handleOpenInternalModal}
                                >
                                  For Internal Use Only
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="prodesc__div">
                              <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="tab__div border-0"
                              >
                                <Tab eventKey="home" title="Items Details">
                                  <div className="tab__contentdiv border__block p-0">
                                    <Spin spinning={diamondDetailsLoading}>
                                      <div className="head__div">
                                        <h4>Your Diamond</h4>
                                      </div>
                                      <div className="desc__maindiv">
                                        <div className="row top__content m-0">
                                          <div className="col-md-12 mt-3">
                                            <div className="row">
                                              <div className="col-md-9">
                                                <p className="font__bold">
                                                  {mountDetails.diamondcarat
                                                    ? mountDetails.diamondcarat
                                                    : "NA"}{" "}
                                                  Carat{" "}
                                                  {mountDetails.diamondcut
                                                    ? mountDetails.diamondcut
                                                    : "NA"}{" "}
                                                  Cut Diamond
                                                </p>
                                                <p>
                                                  This{" "}
                                                  {mountDetails.cutGrade
                                                    ? mountDetails.cutGrade
                                                    : "NA"}
                                                  -cut,{" "}
                                                  {mountDetails.diamondcolor
                                                    ? mountDetails.diamondcolor
                                                    : "NA"}
                                                  -color and{" "}
                                                  {mountDetails.diamondclarity
                                                    ? mountDetails.diamondclarity
                                                    : "NA"}
                                                  -clarity diamond comes
                                                  accompanied by a diamond
                                                  grading report from
                                                  {mountDetails.diamondCertificate
                                                    ? mountDetails.diamondCertificate
                                                    : "NA"}
                                                  .
                                                </p>
                                              </div>
                                              <div className="col-md-3">
                                                <p className="text-right text-left-mobile">
                                                  SKU#{" "}
                                                  <span className="linkText">
                                                    {DiamondId
                                                      ? DiamondId
                                                      : "NA"}
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <div className="detailblk mb-3">
                                              <span className="mr-2 font__bold">
                                                Diamond:{" "}
                                              </span>
                                              {callForPrice ? (
                                                <span>Call For Price</span>
                                              ) : mountDetails.diamondvalue ==
                                                "0" ? (
                                                " Call For Price"
                                              ) : (
                                                <span>
                                                  {currencySymbol}
                                                  {parseInt(
                                                    mountDetails.diamondvalue
                                                  )
                                                    .toFixed()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Spin>
                                    <Spin spinning={settingLoading}>
                                      <div className="head__div">
                                        <h4>Your Setting</h4>
                                      </div>
                                      <div className="desc__maindiv">
                                        <div className="row top__content m-0 flex-column">
                                          <div className="col-md-12 mt-3">
                                            <p className="font__bold">
                                              {mountDetails.mountingHeader
                                                ? mountDetails.mountingHeader
                                                : ""}
                                            </p>
                                            <p>
                                              {mountDetails.mountingDescription
                                                ? mountDetails.mountingDescription
                                                : ""}
                                            </p>
                                            <p>
                                              Style#{" "}
                                              <span className="linkText">
                                                {mountDetails.mountingStyle
                                                  ? mountDetails.mountingStyle
                                                  : ""}
                                              </span>
                                            </p>
                                          </div>

                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>Metal Type</label>
                                              <Select
                                                className="border__grey"
                                                value={
                                                  completeEarringInputData.metalType
                                                    ? completeEarringInputData.metalType
                                                    : []
                                                }
                                                placeholder="Select Metal Type"
                                                optionFilterProp="children"
                                                onChange={(e) =>
                                                  handleCompleteEarringInputChange(
                                                    e,
                                                    "metalType"
                                                  )
                                                }
                                              >
                                                <Option value="">
                                                  Select Metal Type
                                                </Option>
                                                {studMetalTypeList &&
                                                  studMetalTypeList.map(
                                                    (item, i) => {
                                                      return (
                                                        <Option
                                                          value={item.value}
                                                        >
                                                          {item.text}
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </div>
                                          </div>

                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>Metal Color</label>
                                              <Select
                                                className="border__grey"
                                                value={
                                                  completeEarringInputData.metalColor
                                                    ? completeEarringInputData.metalColor
                                                    : []
                                                }
                                                name="metalColor"
                                                placeholder="Select Metal Color"
                                                optionFilterProp="children"
                                                onChange={(e) =>
                                                  handleCompleteEarringInputChange(
                                                    e,
                                                    "metalColor"
                                                  )
                                                }
                                              >
                                                <Option value="">
                                                  Select Metal Color
                                                </Option>
                                                {studMetalColorList &&
                                                  studMetalColorList.map(
                                                    (item, i) => {
                                                      return (
                                                        <Option
                                                          value={item.value}
                                                        >
                                                          {item.text}
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </div>
                                          </div>

                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>Chain Length</label>
                                              <Select
                                                className="border__grey"
                                                value={
                                                  completeEarringInputData.backingType
                                                    ? completeEarringInputData.backingType
                                                    : []
                                                }
                                                name="backingType"
                                                placeholder="Select Chain Length"
                                                optionFilterProp="children"
                                                onChange={(e) =>
                                                  handleCompleteEarringInputChange(
                                                    e,
                                                    "backingType"
                                                  )
                                                }
                                              >
                                                <Option value="">
                                                  Select Backing Type
                                                </Option>
                                                {studBackingTypeList &&
                                                  studBackingTypeList.map(
                                                    (item, i) => {
                                                      return (
                                                        <Option
                                                          value={item.value}
                                                        >
                                                          {item.text}
                                                        </Option>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </div>
                                          </div>

                                          <div className="col-md-12">
                                            <div className="detailblk mb-3">
                                              <span className="mr-2 font__bold">
                                                Setting:{" "}
                                              </span>
                                              {callForPrice ? (
                                                <span>Call For Price</span>
                                              ) : mountDetails.mountingValue ==
                                                "0" ? (
                                                " Call For Price"
                                              ) : (
                                                <span>
                                                  {currencySymbol}
                                                  {parseInt(
                                                    mountDetails.mountingValue
                                                  )
                                                    .toFixed()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Spin>

                                    <div className="desc__maindiv">
                                      <div className="row top__content m-0">
                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-sm-12 col-md-6">
                                            <p onClick={showModal2}>
                                              <span className="img__box">
                                                <img src={drophint} />
                                              </span>
                                              <span>Drop A Hint</span>
                                            </p>
                                          </div>

                                          <div className="col-sm-12 col-md-6">
                                            <p onClick={showModal1}>
                                              <span className="img__box">
                                                <img src={infoblack} />
                                              </span>
                                              <span>Request More Info</span>
                                            </p>
                                          </div>
                                        </div>

                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-sm-12 col-md-6">
                                            <p onClick={showModal3}>
                                              <span className="img__box">
                                                <img src={emailicon} />
                                              </span>
                                              <span>E-Mail A Friend</span>
                                            </p>
                                          </div>

                                          {scheduleHide ? (
                                            <div className="col-sm-12 col-md-6">
                                              <p onClick={showModal5}>
                                                <span className="img__box">
                                                  <img src={calender} />
                                                </span>
                                                <span>Schedule Viewing</span>
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>

                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-sm-12 col-md-6">
                                            <p
                                              onClick={() => {
                                                handlePrintDiamondList();
                                              }}
                                            >
                                              <span className="img__box">
                                                <img src={printicon} />
                                              </span>
                                              <span>Print Details</span>
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-12">
                                          <p>
                                            Have a question regarding this item?
                                            Our specialists are available to
                                            assist you.
                                          </p>
                                        </div>
                                        {adressHide ? (
                                          <div className="col-md-12">
                                            <p className="font__bold d-flex justify-between">
                                              <span className="font__bold">
                                                {locationData.locationName
                                                  ? parse(
                                                    locationData.locationName
                                                  )
                                                  : ""}
                                              </span>
                                            </p>
                                            <p className="font__bold">
                                              <span className="font__bold">
                                                {locationData.phone
                                                  ? locationData.phone
                                                  : ""}
                                              </span>
                                            </p>
                                            <p className="font__bold">
                                              <a
                                                href={`mailto:${locationData.emailID}`}
                                              >
                                                {locationData.emailID
                                                  ? locationData.emailID
                                                  : ""}
                                              </a>
                                            </p>
                                          </div>
                                        ) : null}

                                        <div className="col-md-12">
                                          <div className="dtl__socialsection">
                                            {facebookIcons ? (
                                              <button className="dtl__socialbtn fb__btn">
                                                <img
                                                  src={DtlFacebook}
                                                  onClick={() =>
                                                    handleShareIconsView(
                                                      mountDetails.facbookUrl
                                                    )
                                                  }
                                                />
                                              </button>
                                            ) : null}

                                            {pinterestIcons ? (
                                              <button className="dtl__socialbtn pintrest">
                                                <img
                                                  src={DtlPintrest}
                                                  onClick={() =>
                                                    handleShareIconsView(
                                                      mountDetails.pinterestUrl
                                                    )
                                                  }
                                                />
                                              </button>
                                            ) : null}

                                            {twitterIcons ? (
                                              <button className="dtl__socialbtn tweeter">
                                                <img
                                                  src={DtlTwitter}
                                                  onClick={() =>
                                                    handleShareIconsView(
                                                      mountDetails.twitterUrl
                                                    )
                                                  }
                                                />
                                              </button>
                                            ) : null}
                                            {instaGramIcons ? (
                                              <button className="dtl__socialbtn instagram">
                                                <img
                                                  src={DtlInstagram}
                                                  onClick={() =>
                                                    handleShareIconsView(
                                                      mountDetails.instagramUrl
                                                    )
                                                  }
                                                />
                                              </button>
                                            ) : null}
                                            {facebookLikeIcons ? (
                                              <button className="dtl__socialbtn">
                                                <img
                                                  src={facebookLive}
                                                  className="fbLike"
                                                  onClick={() =>
                                                    handleShareIconsView(
                                                      mountDetails.facebookLikeUrl
                                                    )
                                                  }
                                                />
                                              </button>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </Wrapper>
      <React.Fragment>
        {/* Modal Section Starts */}

        {/* Request More Info Modal Starts */}
        <Modal
          title="Request More Information"
          visible={requestMoreModal}
          style={{ top: 20 }}
          width={700}
          className="request_info"
          maskClosable={false}
          onCancel={closeRequestMoreModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeRequestMoreModal}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={buttonLoading}
              type="primary"
              onClick={handleSubmitRequestMoreInfo}
            >
              Request
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <p>Our specialists will contact you.</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={requestMoreInfoInputData.name}
                        className={
                          requestMoreInfoInputDataValidation.nameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleRequestMoreInfoChange(e, "name", "input")
                        }
                      />
                      {requestMoreInfoInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {requestMoreInfoInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your E-Mail Address <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your E-Mail Address"
                        value={requestMoreInfoInputData.email}
                        className={
                          requestMoreInfoInputDataValidation.emailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleRequestMoreInfoChange(e, "email", "input")
                        }
                      />
                      {requestMoreInfoInputDataValidation.emailval && (
                        <p className="error-color-red">
                          {requestMoreInfoInputDataValidation.emailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Phone Number <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Phone Number"
                        value={requestMoreInfoInputData.phoneNumber}
                        className={
                          requestMoreInfoInputDataValidation.phoneNumberval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleRequestMoreInfoChange(e, "phoneNumber", "input")
                        }
                      />
                      {requestMoreInfoInputDataValidation.phoneNumberval && (
                        <p className="error-color-red">
                          {requestMoreInfoInputDataValidation.phoneNumberval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Contact Preference</label>
                      <div className="row">
                        <div className="radio__block col-6">
                          <input
                            type="radio"
                            value="byEmail"
                            checked={
                              requestMoreInfoInputData.contactPreference ==
                                "byEmail"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleRequestMoreInfoChange(
                                e,
                                "contactPreference",
                                "radio"
                              )
                            }
                          />

                          <label>By Email</label>
                        </div>
                        <div className="radio__block col-6">
                          <input
                            type="radio"
                            value="byPhone"
                            checked={
                              requestMoreInfoInputData.contactPreference ==
                                "byPhone"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleRequestMoreInfoChange(
                                e,
                                "contactPreference",
                                "radio"
                              )
                            }
                          />

                          <label>By Phone</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Add A Personal Message Here...</label>
                      <textarea
                        value={requestMoreInfoInputData.personalMessage}
                        placeholder="Add A Personal Message Here ..."
                        onChange={(e) =>
                          handleRequestMoreInfoChange(
                            e,
                            "personalMessage",
                            "radio"
                          )
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Request More Info Modal Ends */}

        {/* Drop A Hint Modal Starts */}
        <Modal
          title="Drop A Hint"
          visible={showDropModal}
          width={700}
          style={{ top: 20 }}
          className="drop_a_hint"
          maskClosable={false}
          onCancel={hideDropHintModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={hideDropHintModal}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={buttonLoading}
              onClick={handleSubmitDropHint}
            >
              Drop Hint
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={dropHintInputData.name}
                        className={
                          dropHintInputDataValidation.nameval && "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "name", "input")
                        }
                      />
                      {dropHintInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your E-Mail <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your E-Mail"
                        value={dropHintInputData.email}
                        className={
                          dropHintInputDataValidation.emailval && "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "email", "input")
                        }
                      />
                      {dropHintInputDataValidation.emailval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.emailval}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Hint Recipient's Name{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Hint Recipient's Name"
                        value={dropHintInputData.recipientName}
                        className={
                          dropHintInputDataValidation.recipientNameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "recipientName", "input")
                        }
                      />
                      {dropHintInputDataValidation.recipientNameval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.recipientNameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Hint Recipient's E-Mail{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Hint Recipient's E-Mail"
                        value={dropHintInputData.recipientEmail}
                        className={
                          dropHintInputDataValidation.recipientEmailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "recipientEmail", "input")
                        }
                      />
                      {dropHintInputDataValidation.recipientEmailval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.recipientEmailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Reason For This Gift{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Reason For This Gift"
                        value={dropHintInputData.giftReason}
                        className={
                          dropHintInputDataValidation.giftReasonval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "giftReason", "input")
                        }
                      />
                      {dropHintInputDataValidation.giftReasonval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.giftReasonval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Gift Deadline <span className="mandatory">*</span>
                      </label>
                      <input
                        type="date"
                        value={dropHintInputData.giftDeadline}
                        className={
                          dropHintInputDataValidation.giftDeadlineval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "giftDeadline", "date")
                        }
                        min={disablePastDate()}
                      />
                      {dropHintInputDataValidation.giftDeadlineval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.giftDeadlineval}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input__block">
                      <label>
                        Add A Personal Message Here ...
                        <span className="mandatory">*</span>
                      </label>
                      <textarea
                        value={dropHintInputData.personalMessage}
                        placeholder="Add A Personal Message Here ..."
                        className={
                          dropHintInputDataValidation.personalMessageval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "personalMessage", "input")
                        }
                      ></textarea>
                      {dropHintInputDataValidation.personalMessageval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.personalMessageval}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Drop A Hint Modal Ends */}

        {/* E-Mail A Friend Modal Starts */}
        <Modal
          title="E-Mail A Friend"
          visible={emailFriendModal}
          width={700}
          style={{ top: 20 }}
          className="email_a_friend"
          maskClosable={false}
          onCancel={closeEmailFriendModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeEmailFriendModal}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={buttonLoading}
              type="primary"
              onClick={handleSubmitEmailFriend}
            >
              Send To Friend
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={emailFriendInputData.name}
                        className={
                          emailFriendInputDataValidation.nameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "name", "input")
                        }
                      />
                      {emailFriendInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your E-Mail <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your E-Mail"
                        value={emailFriendInputData.email}
                        className={
                          emailFriendInputDataValidation.emailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "email", "input")
                        }
                      />
                      {emailFriendInputDataValidation.emailval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.emailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Friend's Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Friend's Name"
                        value={emailFriendInputData.friendName}
                        className={
                          emailFriendInputDataValidation.friendNameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "friendName", "input")
                        }
                      />
                      {emailFriendInputDataValidation.friendNameval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.friendNameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Friend's E-Mail{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Friend's E-Mail"
                        value={emailFriendInputData.friendEmail}
                        className={
                          emailFriendInputDataValidation.friendEmailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "friendEmail", "input")
                        }
                      />
                      {emailFriendInputDataValidation.friendEmailval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.friendEmailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>
                        Add A Personal Message Here ...
                        <span className="mandatory">*</span>
                      </label>
                      <textarea
                        value={emailFriendInputData.personalMessage}
                        placeholder="Add A Personal Message Here ..."
                        className={
                          emailFriendInputDataValidation.personalMessageval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "personalMessage", "input")
                        }
                      ></textarea>
                      {emailFriendInputDataValidation.personalMessageval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.personalMessageval}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* E-Mail A Friend Modal Ends */}

        {/* Schedule Viewing Modal Starts */}
        <Modal
          title="Schedule A Viewing"
          style={{ top: 20 }}
          width={700}
          visible={scheduleViewing}
          className="schedule_viewing"
          onCancel={closeScheduleViewingModal}
          maskClosable={false}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeScheduleViewingModal}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              type="primary"
              loading={buttonLoading}
              onClick={handleSubmitSheduleViewing}
            >
              Schedule
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <p>See This Items & More In Our Store</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={scheduleViewingInputData.name}
                        className={
                          scheduleViewingInputDataValidation.nameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "name", "input")
                        }
                      />
                      {scheduleViewingInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Email<span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        value={scheduleViewingInputData.email}
                        className={
                          scheduleViewingInputDataValidation.emailVal &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "email", "input")
                        }
                      />
                      {scheduleViewingInputDataValidation.emailVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Phone Number<span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Phone Number"
                        className={
                          scheduleViewingInputDataValidation.phoneNumberVal &&
                          "border__red"
                        }
                        value={scheduleViewingInputData.phoneNumber}
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "phoneNumber", "input")
                        }
                      />
                      {scheduleViewingInputDataValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Location<span className="mandatory">*</span>
                      </label>
                      <Select
                        className={
                          scheduleViewingInputDataValidation.locationVal
                            ? "border__red"
                            : "border__grey"
                        }
                        name="location"
                        optionFilterProp="children"
                        value={scheduleViewingInputData.location}
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "location", "select")
                        }
                      >
                        <Option value="">Select Location</Option>
                        {locationDetails &&
                          locationDetails.map((item, i) => {
                            return (
                              <Option value={item.LocationName}>
                                {item.LocationName}
                              </Option>
                            );
                          })}
                      </Select>
                      {scheduleViewingInputDataValidation.locationVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.locationVal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        When are you available?{" "}
                        <span className="mandatory">*</span>{" "}
                      </label>
                      <input
                        type="date"
                        placeholder="j"
                        value={scheduleViewingInputData.appointmentDate}
                        onChange={(e) =>
                          handleScheduleViewingChange(
                            e,
                            "appointmentDate",
                            "input"
                          )
                        }
                        className={
                          scheduleViewingInputDataValidation.appointmentDateVal &&
                          "border__red"
                        }
                        min={disablePastDate()}
                      />
                      {scheduleViewingInputDataValidation.appointmentDateVal && (
                        <p className="error-color-red">
                          {
                            scheduleViewingInputDataValidation.appointmentDateVal
                          }
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Appointment Time<span className="mandatory">*</span>
                      </label>
                      <Select
                        className={
                          scheduleViewingInputDataValidation.appointmentTimeVal
                            ? "border__red"
                            : "border__grey"
                        }
                        name="appointmentTime"
                        optionFilterProp="children"
                        placeholder="Select Appointment Time"
                        value={scheduleViewingInputData.appointmentTime}
                        onChange={(e) =>
                          handleScheduleViewingChange(
                            e,
                            "appointmentTime",
                            "select"
                          )
                        }
                      >
                        {/* <Option value="">What time do you have open?</Option> */}
                        {getTimedate &&
                          getTimedate.map((item, i) => {
                            return (
                              <Option value={item.value}>{item.text}</Option>
                            );
                          })}
                      </Select>
                      {scheduleViewingInputDataValidation.appointmentTimeVal && (
                        <p className="error-color-red">
                          {
                            scheduleViewingInputDataValidation.appointmentTimeVal
                          }
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>
                        Add A Personal Message Here ...
                        <span className="mandatory">*</span>
                      </label>
                      <textarea
                        value={scheduleViewingInputData.message}
                        placeholder="Add A Message Here ..."
                        className={
                          scheduleViewingInputDataValidation.messageVal &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "message", "input")
                        }
                      ></textarea>
                      {scheduleViewingInputDataValidation.messageVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.messageVal}
                        </p>
                      )}
                    </div>
                  </div>
                  {adressHide ? (
                    <div className="col-md-12">
                      <p className="font__bold d-flex justify-between">
                        <span className="subheading">
                          {locationData.locationName
                            ? parse(locationData.locationName)
                            : ""}
                        </span>
                      </p>
                      <p className="font__bold">
                        <span className="subheading">
                          {locationData.phone ? locationData.phone : ""}
                        </span>
                      </p>
                      <p className="font__bold">
                        <a href={`mailto:${locationData.emailID}`}>
                          {locationData.emailID ? locationData.emailID : ""}
                        </a>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Schedule Viewing Modal Ends */}

        {/* Thumbnail Slider Video Modal Starts */}
        <Modal
          title=""
          style={{ top: 20 }}
          width={700}
          visible={isModalVisible6}
          className="video__modal"
          onCancel={handleCancel6}
          footer={false}
        >
          <div className="video__section">
            <video
              width="100%"
              height="80%"
              src={settingItemDetailsData.videoURL}
              autoPlay
              loop
              controls
            ></video>
          </div>
        </Modal>
        <NotificationContainer />
        {/* Thumbnail Slider Video Modal Ends */}
        {/* For Internal Use Only Modal Starts */}

        <Modal
          title="For Internal Use Only"
          style={{ top: 20 }}
          width={700}
          visible={internalUseModal}
          className="video__modal"
          onCancel={handleCancelInternalModal}
          footer={[
            <Button
              key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={handleCancelInternalModal}
            >
              Cancel
            </Button>,

            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={internalUseModalLoading}
              type="primary"
              onClick={HandleCallForInternal}
            >
              Submit
            </Button>,
          ]}
        >
          {showDealerInfo == true ? (
            <div className="form__fields border-0 mh-aut0">
              {dealerInfo &&
                dealerInfo.map((item, i) => {
                  return (
                    <div>
                      <p>
                        Vendor Name: {item.vendorName ? item.vendorName : "NA"}
                      </p>
                      <p>
                        Vendor City/State :{" "}
                        {item.city || item.state
                          ? item.city + "/" + item.state
                          : "NA"}
                      </p>
                      <p>
                        Vendor Phone Number:{" "}
                        {item.dealerPhone ? item.dealerPhone : "NA"}
                      </p>
                      <p>
                        Vendor E-Mail:{" "}
                        {item.dealerEmail ? item.dealerEmail : "NA"}
                      </p>
                      <p>
                        Vendor lot number of the item:{" "}
                        {item.dealerInventoryNo ? item.dealerInventoryNo : "NA"}
                      </p>
                      <p>
                        WholeSale Price:{" "}
                        {item.dealerCost ? item.dealerCost : "NA"}
                      </p>
                      <p> WholeSale Price: { }</p>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="form__fields">
              <div className="col-md-6">
                <div className="input__block">
                  <label htmlFor="">
                    GF Password <span className="mandatory">*</span>
                  </label>
                  <input
                    type="password"
                    value={internalUseData.password}
                    className={internalUseDataval.passwordval && "border__red"}
                    onChange={(e) =>
                      handlepasswordMoreInfoChange(e, "password", "input")
                    }
                  />
                  {internalUseDataval.passwordval && (
                    <p className="error-color-red">
                      {internalUseDataval.passwordval}
                    </p>
                  )}
                </div>
                <span
                  className="reset__pass"
                  onClick={() => {
                    setInternalUseData({
                      password: "",
                    })
                    window.open(`${window.location.ancestorOrigins[0]}/?resetField=true`)
                  }
                  }
                  style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                >
                  Reset Password
                </span>
              </div>
            </div>
          )}
        </Modal>

        {/* For Internal Use Only Modal Ends */}

        <Modal
          title=""
          visible={isModalVisibleFullscreen}
          className="fullscreen__modal"
          onCancel={handleCancelFullscreen}
          footer={false}
          width={700}
          style={{ top: 20 }}
        >
          <div className="thimage__slider text-center">
            <img
              src={mountDetails.image ? mountDetails.image : ""}
              alt=""
              className="img-fluid"
            />
          </div>
        </Modal>

        {/* Modal Section Ends */}
      </React.Fragment>
    </React.Fragment>
  );
};

export default CompleteYourRing;
