// import {
//   VIEW_DIAMOND_DETAILS_PAGE,
//   HIDE_DIAMOND_DETAILS_PAGE,
//   SET_COMPARE_TAB_IDS,
//   SET_DIAMOND_ACTIVE_TAB
// } from "../../actions/selectYourDiamond/types";

// const initialState = {
//   isViewDetails: false,
//   selectedRow: null,
//   compareData: null,
//   activeDiamondTab: "Mined"
// };

// export default function handleSelectYourDiamondReducer(
//   selectYourDiamond = initialState,
//   action
// ) {
//   const { type, payload } = action;
//   switch (type) {
//     case VIEW_DIAMOND_DETAILS_PAGE:
//       return {
//         ...selectYourDiamond,
//         isViewDetails: true,
//         selectedRow: payload
//       };
//     case HIDE_DIAMOND_DETAILS_PAGE:
//       return {
//         ...selectYourDiamond,
//         isViewDetails: false,
//         selectedRow: null
//       };
//     case SET_DIAMOND_ACTIVE_TAB:
//       return {
//         ...selectYourDiamond,
//         activeDiamondTab: payload
//       };

//     case SET_COMPARE_TAB_IDS:
//       return {
//         ...selectYourDiamond,
//         compareIds: payload
//       };

//     default:
//       return selectYourDiamond;
//   }
// }

import {
  VIEW_DIAMOND_DETAILS_PAGE,
  HIDE_DIAMOND_DETAILS_PAGE,
  SET_COMPARE_TAB_IDS,
  SET_RESETMINED_TAB,
  SET_RESETLAB_TAB,
  SET_RESETFANCY_TAB,
  SET_RESETREQUEST_TAB,
  SET_DIAMOND_DETAILS,
  SET_SAVESEARCH_MINEDTAB,
  SET_SAVEFILTER_MINEDTAB,
  SET_SAVEEVERYTIME_MINEDTAB,
  SET_SWITCHFILTER_MINEDTAB,
  SET_SAVESEARCH_LABTAB,
  SET_SAVEFILTER_LABTAB,
  SET_SAVEEVERYTIME_LABTAB,
  SET_SWITCHFILTER_LABTAB,
  SET_SAVESEARCH_FANCYTAB,
  SET_SAVEFILTER_FANCYTAB,
  SET_SAVEEVERYTIME_FANCYTAB,
  SET_SWITCHFILTER_FANCYTAB,
  SET_DIAMOND_ACTIVE_TAB,
  SET_COMPARE_PAGE_KEY
} from "../../actions/selectYourDiamond/types";

const initialState = {
  isViewDetails: false,
  selectedRow: null,
  compareData: null,
  resetMined: true,
  resetLab: true,
  resetFancy: true,
  resetRequest: true,
  activeDiamondTab: "Mined",

  saveSearchMined: false,
  minedDiamondFilter: {
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    Certificate: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    disabled: {}
  },
  everyTimeFilterChangeMined: {},
  saveSearchLab: false,
  LabDiamondFilter: {
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    Certificate: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    disabled: {}
  },
  everyTimeFilterChangeLab: {},

  saveSearchFancy: false,
  FancyDiamondFilter: {
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    Intensity: [],
    Certificates: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    disabled: {}
  },
  everyTimeFilterChangeFancy: {},
  compareIds: [],
  compareKey:"",
  diamondDetails: {}
};

export default function handleSelectYourDiamondReducer(
  selectYourDiamond = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case VIEW_DIAMOND_DETAILS_PAGE:
      return {
        ...selectYourDiamond,
        isViewDetails: true,
        selectedRow: payload
      };
    case SET_DIAMOND_ACTIVE_TAB:
      return {
        ...selectYourDiamond,
        activeDiamondTab: payload
      };
    case HIDE_DIAMOND_DETAILS_PAGE:
      return {
        ...selectYourDiamond,
        isViewDetails: false,
        selectedRow: null
      };

    case SET_COMPARE_TAB_IDS:
      return {
        ...selectYourDiamond,
        compareIds: payload
      };
    case SET_RESETMINED_TAB:
      return {
        ...selectYourDiamond,
        resetMined: !selectYourDiamond.resetMined
      };
    case SET_RESETLAB_TAB:
      return {
        ...selectYourDiamond,
        resetLab: !selectYourDiamond.resetLab
      };
    case SET_RESETFANCY_TAB:
      return {
        ...selectYourDiamond,
        resetFancy: !selectYourDiamond.resetFancy
      };
    case SET_RESETREQUEST_TAB:
      return {
        ...selectYourDiamond,
        resetRequest: !selectYourDiamond.resetRequest
      };
    case SET_COMPARE_TAB_IDS:
      return {
        ...selectYourDiamond,
        compareIds: payload
      };
    case SET_DIAMOND_DETAILS:
      return {
        ...selectYourDiamond,
        diamondDetails: payload
      };
    case SET_SAVESEARCH_MINEDTAB:
      return {
        ...selectYourDiamond,
        saveSearchMined: payload
      };
    case SET_SAVEFILTER_MINEDTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeMined: payload
      };

    case SET_SAVEEVERYTIME_MINEDTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeMined: payload
      };
    case SET_SWITCHFILTER_MINEDTAB:
      return {
        ...selectYourDiamond,
        minedDiamondFilter: selectYourDiamond.everyTimeFilterChangeMined
      };

    case SET_SAVESEARCH_LABTAB:
      return {
        ...selectYourDiamond,
        saveSearchLab: payload
      };
    case SET_SAVEFILTER_LABTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeLab: payload
      };

    case SET_SAVEEVERYTIME_LABTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeLab: payload
      };
    case SET_SWITCHFILTER_LABTAB:
      return {
        ...selectYourDiamond,
        LabDiamondFilter: selectYourDiamond.everyTimeFilterChangeLab
      };

    case SET_SAVESEARCH_FANCYTAB:
      return {
        ...selectYourDiamond,
        saveSearchFancy: payload
      };
    case SET_SAVEFILTER_FANCYTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeFancy: payload
      };

    case SET_SAVEEVERYTIME_FANCYTAB:
      return {
        ...selectYourDiamond,
        everyTimeFilterChangeFancy: payload
      };
    case SET_SWITCHFILTER_FANCYTAB:
      return {
        ...selectYourDiamond,
        FancyDiamondFilter: selectYourDiamond.everyTimeFilterChangeFancy
      };
      case SET_COMPARE_PAGE_KEY:
      return {
        ...selectYourDiamond,
        compareKey: payload,
      };

    default:
      return selectYourDiamond;
  }
}
