import React, { useEffect, useState } from "react";
import selectYourDiamondService from "../services/select-your-diamond.service";

import { useLocation } from "react-router-dom";

export const Footer = () => {
  const search = useLocation().search;
  const paramDealerId = new URLSearchParams(search).get("DealerLink");
  const [visible, setVisible] = useState(false);
  const [whiteLableName, setWhiteLableName] = useState("");
  useEffect(() => {
    handleGetMenuList(paramDealerId);
    handleGetWhiteLabel();
  }, []);

  const handleGetMenuList = (Id) => {
    try {
      let inputData = {
        dealerId: Id,
      };

      selectYourDiamondService
        .GetMenuList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            setVisible(responseData.diamondSearchMenu.chkPBSPBy);
          } else {
            setVisible(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetWhiteLabel = () => {
    try {
      selectYourDiamondService
        .CompanyWhileLabel()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setWhiteLableName(responseData[0].name);
            } else {
              setWhiteLableName("");
            }
          } else {
            setWhiteLableName("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="powered__by">
      {visible == true && (
        <p>Powered By {whiteLableName ? whiteLableName : ""}</p>
      )}
    </div>
  );
};

export default Footer;
