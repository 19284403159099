import { httpApps } from "../helper/http-common";

class CompleteYourRingService {
  SaveDropHint(inputData) {
    return httpApps.post("RingBuilder/SaveDropHint", inputData);
  }
  SaveRequestInfo(inputData) {
    return httpApps.post("RingBuilder/SaveRequestInfo", inputData);
  }
  SaveEmailAFriend(inputData) {
    return httpApps.post("RingBuilder/SaveEmailAFriend", inputData);
  }
  SaveScheduleAViewing(inputData) {
    return httpApps.post("RingBuilder/SaveScheduleAViewing", inputData);
  }

  GetAppPrevData(inputData) {
    return httpApps.post("RingBuilder/GetAppPrevData", inputData);
  }
  GetStudDropDownData(inputData) {
    return httpApps.post("studBuilder/GetStudDropDownData", inputData);
  }
  LoadSettingsDetails(inputData) {
    return httpApps.post("PendantBuilder/LoadSettingsDetails", inputData);
  }
  LoadDiamondDetails(inputData) {
    return httpApps.post("PendantBuilder/LoadDiamondDetails", inputData);
  }
  ChangeProductConfigurationPend(inputData) {
    return httpApps.post(
      "PendantBuilder/ChangeProductConfigurationPend",
      inputData
    );
  }
}

export default new CompleteYourRingService();
