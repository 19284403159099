import { httpApps } from "../helper/http-common";

class ColorPanelServices {
  GetHoverEffect(inputData) {
    return httpApps.post("RingBuilder/GetHoverEffect", inputData);
  }
  AddUpdateHoverEffect(inputData) {
    return httpApps.post("RingBuilder/AddUpdateHoverEffect", inputData);
  }
}

export default new ColorPanelServices();
